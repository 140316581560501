import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {BehaviorSubject, lastValueFrom} from "rxjs";
import {environment} from "../../../environments/environment";
import {CompanyModel, PreferencesModel} from "../models/company.model";

@Injectable({providedIn: 'root'})
export class CompanyService {
  public company$: BehaviorSubject<CompanyModel | undefined> = new BehaviorSubject<CompanyModel | undefined>(undefined);
  private readonly baseUrl = `${environment.API_BASE_URL}/businesses`;

  constructor(private http: HttpClient,) {
  }

  public async get(): Promise<CompanyModel> {
    const request = this.http.get<CompanyModel>(this.baseUrl + '/me')
    let company = await lastValueFrom(request)
    this.company$.next(company)
    return company
  }

  public async setPreferences(preferences?: PreferencesModel): Promise<void> {
    const request = this.http.post(`${environment.API_BASE_URL}/preferences`, {
      bankAccount: preferences?.bankAccount,
      remittance: preferences?.remittance,
      creditorRemittance: preferences?.creditorRemittance,
      payday: preferences?.payday
    })
    await lastValueFrom(request)
  }
}
