import {Injectable} from "@angular/core";
import {NgxSpinnerService, Spinner} from "ngx-spinner";
import {LoggerService} from "./logger.service";

@Injectable({providedIn: 'root'})
export class SpinnerService {


  constructor(
    private spinner: NgxSpinnerService,
    private logger: LoggerService
  ) {
  }

  async show(name: string, reason: string, spinner?: Spinner | undefined): Promise<unknown> {
    this.logger.debug(`show spinner ${name} for reason ${reason}`)
    return await this.spinner.show(name, spinner)
  }

  async hide(name: string, reason: string, debounce?: number | undefined): Promise<unknown> {
    this.logger.debug(`hide spinner ${name} for reason ${reason}`)
    return await this.spinner.hide(name, debounce)
  }
}

